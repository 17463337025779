<template>
    <div class="flex flex-col h-full gap-y-8">
        <div class="">
            <div class="mb-8 lg:mb-16">
                <div class="bg-white pt-4 px-4">
                    <div class="border-teal border-2 border-b-0 text-center pt-4 pb-5">
                        <span class="text-red font-script text-5xl">Menus</span>
                    </div>
                </div>

                <div class="section-pattern pb-4 px-4">
                    <div class="border-teal border-2 border-t-0 text-center pt-4 pb-5 px-4">
                        <MenuNav />
                    </div>
                </div>
            </div>
            <slot></slot>

            <div class="mt-16 text-center">
                <div class="mb-5"><span class="text-red font-script text-5xl">Downloads</span></div>
                <div class="flex flex-col space-y-3">
                    <div v-for="button in downloads" class="w-100 px-6">
                        <a :href="button.url" target="_blank" rel="noopener" class="btn btn-outline group w-full block">
                            <div class="flex items-center justify-between">
                                <span>{{ button.label }}</span>
                                <ArrowDownTrayIcon class="stroke-[3] w-4 h-4 transition-transform group-hover:translate-x-2"></ArrowDownTrayIcon>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="hidden lg:block text-center sticky top-10">
            <a href="#menu" class="btn btn-outline btn-sm">
                <div class="flex space-x-4">
                    <ArrowUpCircleIcon class="w-6 h-6" />
                    <div>Back to Top</div>
                </div>
            </a>
        </div>
    </div>
</template>

<script setup>
import MenuNav from './Nav.vue';
import { ArrowDownTrayIcon, ArrowUpCircleIcon } from '@heroicons/vue/24/outline';
import { inject } from 'vue';

const downloads = inject('downloads');
</script>
