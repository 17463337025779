<template>
    <div>
        <template v-for="(category, categoryId) in categories">
            <div v-if="category.items.length">
                <template v-if="categoryId == 'uncategorized'">
                    <div v-for="section in category.items">
                        <a :href="'#' + section.slug" class="menu-nav-link">{{ section.name }}</a>
                        <Arrow :flat="true" />
                    </div>
                </template>

                <template v-else>
                    <div class="menu-nav-item menu-nav-item-dropdown" :class="{ 'menu-nav-item-dropdown-open': categoryId == active }">
                        <a :href="'#' + categoryId" @click.prevent="toggleActive(categoryId)" class="menu-nav-link">
                            {{ category.name }}
                        </a>
                        <ul class="menu-nav-dropdown my-0">
                            <li v-for="section in category.items">
                                <a :href="'#' + section.slug">{{ section.name }}</a>
                            </li>
                        </ul>
                        <a :href="'#' + categoryId" @click.prevent="toggleActive(categoryId)">
                            <Arrow :flat="active == categoryId" />
                        </a>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script setup>
import Arrow from './Arrow.vue';
import { inject, ref } from 'vue';

const categories = inject('categories');
const active = ref(null);

function toggleActive(categoryId) {
    active.value = active.value == categoryId ? null : categoryId;
}
</script>
