<template>
    <div class="footer-cta-inner">
        <div class="footer-cta-image">
            <img :src="image" class="delivery-truck w-full h-auto block" ref="imageEl" />
        </div>

        <div class="footer-cta-content">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import ScrollReveal from 'scrollreveal';

defineProps({
    image: {
        type: String,
        required: true,
    },
});

const imageEl = ref(null);

onMounted(() => {
    ScrollReveal({
        reset: true,
        viewOffset: { bottom: 24 },
    }).reveal(imageEl.value, {
        distance: '100%',
        origin: 'left',
        duration: 1000,
        opacity: 1,
        easing: 'linear',
    });
});
</script>
