import '../styles/dot.css';
import { createApp } from 'vue';
import ContactFaqs from './components/ContactFaqs.vue';
import ImageGallery from './components/ImageGallery.vue';
import InlineSvg from 'vue-inline-svg';
import MenuViewer from './components/Menu/Viewer.vue';
import Navigation from './components/Navigation.vue';
import PappasLeadsForm from '@pappas/leads-form';
import PappasEclub from '@pappas/eclub';
import FooterCtaDelivery from './components/FooterCtas/Delivery.vue';
import FooterCtaGiftCard from './components/FooterCtas/GiftCard.vue';
// import PappasLeadsForm from '../../../pappas-leads-form/src/index.js';

const app = createApp({
    methods: {
        leadsFormInitialized() {
            if(window.location.hash == '#address') {
                document.querySelector('#addressTarget').scrollIntoView(true);
            }
        },
    },
});

app.use(PappasLeadsForm);
app.use(PappasEclub);

app.component('inline-svg', InlineSvg);
app.component('navigation', Navigation);
app.component('contact-faqs', ContactFaqs);
app.component('menu-viewer', MenuViewer);
app.component('image-gallery', ImageGallery);
app.component('footer-cta-delivery', FooterCtaDelivery);
app.component('footer-cta-gift-card', FooterCtaGiftCard);

app.mount('#app');
