<template>
    <div class="flex flex-col lg:flex-row gap-x-24 gap-y-16 items-center lg:items-stretch">
        <div class="w-full lg:w-[320px] min-w-[320px] max-w-[400px] md:max-w-max">
            <MenusSidebar>
                <slot name="extras"></slot>
            </MenusSidebar>
        </div>
        <div class="flex-auto max-w-full">
            <MenusBody>
                <slot name="before"></slot>
            </MenusBody>
        </div>
    </div>
</template>

<script setup>
import MenusBody from './Body.vue';
import MenusSidebar from './Sidebar.vue';
import { provide } from 'vue';

const props = defineProps({
    menu: {
        type: Object,
        required: true,
    },
    downloads: {
        type: Array,
        default: () => [],
    },
    images: {
        type: Array,
        default: () => [],
    },
});

provide('categories', props.menu);
provide('images', props.images);
provide('downloads', props.downloads);
</script>
