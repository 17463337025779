<template>
    <div class="gallery" ref="glideEl">
        <div class="glide">
            <div class="glide__track" data-glide-el="track">
                <ul class="glide__slides">
                    <li v-for="item in items" class="glide__slide">
                        <div class="gallery-image">
                            <img :src="item.image.url" :alt="item.image.alt ? item.image.alt : item.description" />
                        </div>
                    </li>
                </ul>
            </div>

            <div class="glide__bullets flex space-x-3 justify-center -translate-y-1/2 mb-4" data-glide-el="controls[nav]">
                <button v-for="(item, idx) in items" class="glide__bullet" :data-glide-dir="'=' + idx"></button>
            </div>

            <div v-if="showNav" class="gallery-nav flex items-start justify-center gap-x-16" data-glide-el="controls">
                <button class="glide__arrow" data-glide-dir="<">
                    <inline-svg src="/app/themes/dot/images/arrow-left.svg"></inline-svg>
                </button>

                <div class="w-full max-w-[250px] text-center relative">
                    <div v-for="(item, idx) in items" class="absolute top-0 left-0 w-full h6 text-teal transition-opacity" :class="[idx === activeIndex ? 'opacity-100 z-10' : 'opacity-0 z-0']">
                        {{ item.description }}
                    </div>
                </div>

                <button class="glide__arrow" data-glide-dir=">">
                    <inline-svg src="/app/themes/dot/images/arrow-left.svg" class="rotate-180"></inline-svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import Glide, { Controls, Breakpoints, Swipe, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
    items: { type: Array, default: () => [] },
    showNav: { type: Boolean, default: true },
});

const glideOptions = {
    type: 'carousel',
    startAt: 0,
    perView: 1,
    autoplay: 3500,
};

const glideEl = ref(null);
let glide = null;

const activeIndex = ref(0);

onMounted(() => {
    glide = new Glide(glideEl.value, glideOptions).mount({ Controls, Breakpoints, Swipe, Autoplay });

    glide.on('run', () => {
        activeIndex.value = null;
    });
    glide.on('run.after', () => {
        activeIndex.value = glide.index;
    });
});

onUnmounted(() => glide.destroy());
</script>
