<template>
    <div class="footer-cta-inner">
        <div class="footer-cta-image">
            <img :src="image" ref="imageEl" :style="style" />
        </div>

        <div class="footer-cta-content">
            <slot></slot>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

defineProps({
    image: {
        type: String,
        required: true,
    }
})

const imageEl = ref(null);
const currentScroll = ref(0);
const threshold = ref([]);
const offset = ref(100);

const style = computed(() => {
    return {
        // transform: 'translateY(-' + offset.value + '%)',
    };
});

onMounted(() => {
    // updateThreshold();
    // document.addEventListener('scroll', handleScroll);
    // window.addEventListener('resize', updateThreshold);
});

/**
 * Update the threshold for triggering the animation
 */
function updateThreshold() {
    const { top } = imageEl.value.getBoundingClientRect();

    threshold.value = [window.pageYOffset + top, window.pageYOffset + top + image.value.clientHeight];
}

/**
 * Handle scroll events, updating the image's offset position based
 * on the current scroll height.
 */
function handleScroll() {
    currentScroll.value = window.scrollY + (window.innerHeight || document.documentElement.clientHeight) - 270;

    if (currentScroll.value < threshold.value[0]) {
        offset.value = 100;
    } else if (currentScroll.value > threshold.value[1]) {
        offset.value = 0;
    } else {
        const a = threshold.value[1] - threshold.value[0];
        const b = currentScroll.value - threshold.value[0];

        offset.value = Math.abs(((a - b) / ((a + b) / 2)) * 100);
    }
}
</script>
