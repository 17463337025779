<template>
    <div class="pt-4">
        <slot></slot>

        <div class="menu-sections">
            <MenuSection v-for="(section, idx) in sections" :section="section" :divide="shouldDivide(idx)" :show-back="isOdd(idx)" />
        </div>
    </div>
</template>

<script setup>
import MenuSection from './Section.vue';
import { computed, inject } from 'vue';

const categories = inject('categories');

const sections = computed(() => {
    const result = [];

    Object.values(categories).forEach((category) => {
        result.push(...category.items);
    });

    return result;
});

function shouldDivide(index) {
    const current = sections.value[index];

    if (current.layout == 'default' && index < sections.value.length - 1) {
        return sections.value[index + 1].layout == 'default';
    }

    return false;
}

function isOdd(cmp) {
    return cmp % 2 !== 0;
}
</script>
