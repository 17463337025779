<template>
    <div :id="section.slug" :class="['menu-section', 'menu-section-' + layout, divide ? 'menu-section-divide' : null, section.image ? 'menu-section-has-image' : null]">
        <div class="mb-8 menu-section-header">
            <div class="font-serif text-[2.66rem] text-teal">{{ section.name }}</div>
            <div v-if="section.descriptionHtml" class="mt-3 leading-tight max-w-3xl" v-html="section.descriptionHtml"></div>
        </div>

        <div class="menu-section-items">
            <div v-for="item in section.items" class="menu-section-item flex flex-col basis-full lg:basis-1/2 lg:pr-5">
                <div class="font-serif text-red text-lg">{{ item.name }}</div>
                <div class="text-sm">{{ item.description }}</div>

                <div v-if="item.cost && item.cost.type == 'PRICE'" class="text-orange">{{ item.cost.price }}</div>

                <template v-if="item.cost && item.cost.options">
                    <div v-for="opt in item.cost.options" class="text-orange">
                        {{ opt.name }}
                        {{ opt.price }}
                    </div>
                </template>
            </div>
        </div>

        <div v-if="section.image" class="menu-section-image">
            <img :src="section.image.url" :alt="section.image.alt" class="block w-100 h-auto" />
        </div>

        <div v-if="showBack" class="block lg:hidden text-center mt-8 -mb-4">
            <a href="#menu" class="inline-block bg-teal-light px-3 py-2 text-xs">
                <div class="flex space-x-1 items-center">
                    <ArrowUpCircleIcon class="w-5 h-5" />
                    <div>Back to Top</div>
                </div>
            </a>
        </div>
    </div>
</template>

<script setup>
import { ArrowUpCircleIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
    section: {
        type: Object,
        required: true,
    },
    divide: {
        type: Boolean,
        required: true,
    },
    showBack: {
        type: Boolean,
        default: false,
    },
});

const layout = props.section.layout ? props.section.layout : 'default';
</script>
