<template>
    <header class="bg-white h-24 md:h-32 lg:h-44">
        <nav class="mx-auto flex items-center h-full max-w-7xl justify-between px-6 lg:px-8" aria-label="Global">
            <div class="flex lg:flex-1">
                <a :href="homeUrl" class="-mx-1.5 -mb-6 pt-4 lg:pt-6 text-red hover:text-[#751013] transition-colors" :target="homeUrl.length > 1 ? '_blank' : '_self'">
                    <span class="sr-only">{{ name }}</span>
                    <InlineSvg :title="name" :src="logo" class="h-28 md:h-32 lg:h-40 w-auto"></InlineSvg>
                </a>
            </div>

            <div v-if="canToggle && !minimal" class="flex lg:hidden">
                <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-teal" @click="mobileMenuOpen = true">
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon class="h-10 lg:h-12 w-10 lg:w-12" aria-hidden="true" />
                </button>
            </div>

            <div v-if="!minimal" class="hidden lg:flex gap-x-10 xl:gap-x-16 items-center">
                <a
                    v-for="item in items"
                    :key="'link-' + item.id"
                    :href="item.url"
                    :class="[
                        item.classes,
                        isActive(item) ? 'border-orange' : 'border-orange/0',
                        !item.classes.includes('btn') ? 'hover:text-orange font-serif text-olive transition-colors border-t-2 border-b-2 py-1 text-base xl:text-lg' : 'hover:text-brown',
                    ]"
                    :target="item.target"
                >
                    {{ item.title }}
                </a>
            </div>
        </nav>

        <Dialog v-if="canToggle && !minimal" as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
            <div class="fixed inset-0 z-10" />
            <DialogPanel class="z-30 fixed inset-y-0 right-0 w-full overflow-y-auto bg-tan-lighter sm:max-w-sm sm:ring-1 sm:ring-blue/10">
                <div class="container">
                    <div class="flex flex-col min-h-screen">
                        <div class="flex items-center justify-end h-24 md:h-32 lg:h-44">
                            <button type="button" class="-m-1.5 inline-flex items-center justify-center rounded-md p-2.5 text-teal" @click="mobileMenuOpen = false">
                                <span class="sr-only">Open main menu</span>
                                <XMarkIcon class="h-10 lg:h-12 w-10 lg:w-12" aria-hidden="true" />
                            </button>
                        </div>

                        <div class="flex flex-col grow mb-12 space-y-5 md:space-y-8">
                            <div v-for="item in items" :key="'mlink-' + item.id" class="text-center flex justify-center last:grow last:flex last:flex-col last:justify-end group">
                                <a
                                    :href="item.url"
                                    :class="['font-semibold py-2 group-last:py-5 px-3 text-3xl lg:text-base leading-7 text-gray-900 group-last:text-white font-serif text-blue transition-colors hover:text-orange', item.classes]"
                                    :target="item.target"
                                >
                                    {{ item.title }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </Dialog>
    </header>
</template>

<script setup>
import { computed, ref } from 'vue';
import { Dialog, DialogPanel } from '@headlessui/vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import InlineSvg from 'vue-inline-svg';

const props = defineProps({
    /**
     * Website name (e.g. Dot Coffee Shop)
     */
    name: {
        type: String,
        required: true,
    },

    /**
     * Navigation bar logo url
     */
    logo: {
        type: String,
        required: true,
    },

    /**
     * Navigation items.
     */
    items: {
        type: Array,
        required: true,
    },

    /**
     * Currently active page id
     */
    active: {
        type: Number,
        default: null,
    },

    /**
     * Whether the nav can be toggled from
     * this instance of the navigation.
     */
    canToggle: {
        type: Boolean,
        default: true,
    },

    /**
     * Whether to display the minimal header
     * for use when testing the e-club
     * implementation prior to launch.
     */
    minimal: {
        type: Boolean,
        default: false,
    },
});

const mobileMenuOpen = ref(false);

const isActive = (item) => parseInt(item.object_id) === props.active;

const homeUrl = computed(() => (props.minimal ? 'https://dotcoffeeshop.com' : '/'));
</script>
